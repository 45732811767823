<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <v-card tile outlined height="500">
          <v-card-title class="text-center justify-center py-10">
            <h1 class="text-center display-1">
              <span class="logo-cap-class">Class</span><span class="logo-cap-i"> i</span> 로그인
            </h1>
          </v-card-title>
          <!-- <v-tabs
            background-color="grey lighten-2"
            color="deep-purple accent-4"
            grow
          > -->
          <v-tabs
            background-color="grey lighten-4"
            color="deep-purple accent-4"
            grow
            v-model="activeTab"
          >
            <v-tab>선생님</v-tab>
            <v-tab>학생</v-tab>
            <v-tab-item>
              <v-card-text class="my-6">
                  <v-btn color="primary my-16" block dark @click="signInWithGoogle">
                    <v-icon>mdi-google</v-icon>
                    <v-divider vertical class="mx-3"></v-divider>
                    Google 계정으로 로그인
                  </v-btn>

              </v-card-text>

            </v-tab-item>
            <v-tab-item>
              <v-form ref="form" lazy-validation>
                <v-card-text>

                    <v-text-field
                    label="아이디"
                    v-model="newform.userid"
                    prepend-icon="mdi-card-account-details"
                    type="text"
                    :rules="[rule.required]"
                    required
                    color="primary"/>
                    <v-text-field
                    label="패스워드"
                    v-model="newform.password"
                    prepend-icon="mdi-lock"
                    :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPw ? 'text' : 'password'"
                    @click:append="showPw = !showPw"
                    :rules="[rule.required, rule.pwRule]"
                    required
                    @keydown.enter="signInStudent"
                    color="primary" />

                </v-card-text>
              </v-form>
              <v-card-text class="text-center">
                <v-btn color="primary" block dark @click="signInStudent">
                  <v-icon>mdi-login</v-icon>
                  <v-divider vertical class="mx-5"></v-divider>
                  로그인
                </v-btn>
              </v-card-text>
            </v-tab-item>
          </v-tabs>
          <v-card-text class="text-center">
            <div class="text--disabled">
              <p v-html="infoText"></p>
            </div>
          </v-card-text>
          <v-card-text class="error--text text-center text-caption">
            크롬(Chrome)이나 엣지(Edge) 브라우저로 접속하세요!
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="$store.state.sb.act"
      :color="$store.state.sb.color"
      timeout="5000"
    >
      {{ $store.state.sb.msg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="$store.commit('pop', { act: false })"
        >
          닫기
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
export default {
  data () {
    return {
      activeTab: 0,
      dialog: false,
      email: '',
      password: '',
      ipAddr: '',
      newform: {
        userid: '',
        password: ''
      },
      infoText: '이 페이지는 Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">개인정보처리방침</a> 및 <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">서비스 약관</a>의 적용을 받습니다.',
      rule: {
        required: v => !!v || '필수 항목입니다.',
        email: v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || '올바른 이메일을 입력하세요.'
        },
        pwRule: v => {
          // 영문 대,소문자, 숫자, 특수문자, 8자리 이상
          // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
          // 영문자, 숫자, 특수문자, 8자리 이상
          // const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
          // return pattern.test(v) || '영문, 숫자, 특수문자 조합 8글자 이상이어야 합니다.'
          // 영문자, 숫자, 8자리 이상
          const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/
          return pattern.test(v) || '영문, 숫자 조합 8글자 이상이어야 합니다.'
        },
        acceptTerms: v => !!v || '이용약관에 동의해야 진행됩니다.'
      },
      resetEmail: '',
      showPw: false,
      overlay: false
    }
  },
  created () {
    const t = localStorage.getItem('activeTab')
    if (t > 0) this.activeTab = 1
  },
  methods: {
    async signInWithGoogle () {
      localStorage.setItem('activeTab', 0)
      const provider = new this.$firebase.auth.GoogleAuthProvider()
      this.$firebase.auth().languageCode = 'ko'
      try {
        const sn = await this.$firebase.auth().signInWithPopup(provider)
        this.$store.dispatch('getUser', sn.user)

        if (this.$store.state.user) {
          const r = await this.$firebase.firestore().collection('users').doc(sn.user.uid).get()

          if (r.exists) {
            this.acceptTerms = r.data().acceptTerms
          }

          if (this.acceptTerms === undefined || this.acceptTerms === 'N') {
            this.$router.push('/manage/checkTerms')
          } else {
            this.$router.push('/manage/academy')
          }
        }
      } finally {
        //
      }
    },
    async signOut () {
      if (this.$firebase.auth().currentUser) {
        this.$firebase.auth().signOut()
      }
    },
    async signInStudent () {
      if (!this.$refs.form.validate()) {
        return
      }
      localStorage.setItem('activeTab', 1)

      // Firefox, IE11 체크해서 실행 안하게
      const isFirefox = typeof InstallTrigger !== 'undefined'
      const isIE = /* @cc_on!@ */false || !!document.documentMode
      if (isFirefox || isIE) {
        this.$store.commit('pop', { msg: '크롬 또는 엣지로 접속하세요', color: 'warning' })
        return
      }
      // IP 확인
      await this.getAddr()

      // 학생 로그인
      try {
        this.overlay = true
        const res = await this.$axios.post('sign/in', {
          userid: this.newform.userid,
          password: this.newform.password,
          ipAddress: this.ipAddr
        })

        this.$store.commit('setStdUser', res.data.user)
        localStorage.setItem('stdt', res.data.token)
        localStorage.setItem('stduser', JSON.stringify(res.data.user))

        this.$router.push('/std/library')
      } catch (e) {
        if (e.response.data) {
          this.$toast.error(e.response.data.message)
          // console.log(e.response.data.message)
          // console.log(e.response.status)
        } else {
          this.$toast.error('아이디나 패스워드가 일치하지 않습니다.')
        }
      } finally {
        this.overlay = false
      }
    },
    goTerms () {
      window.open('/terms', 'Popup')
    },
    async getAddr () {
      // 공인IP 사이트 : https://extreme-ip-lookup.com/json   https://api.ip.pe.kr/json/    https://ipstack.com

      const r = await this.$http.get('https://api.ip.pe.kr/json/')
      if (r.status === 200 && r.ok) {
        this.ipAddr = r.body.ip
        // console.log(this.ipAddr)
      }
    }
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:wght@300;400;600;700;800&display=swap');

.logo-cap-class {
  font-family: 'Open Sans', sans-serif;
  text-align : center;
}
.logo-cap-i {
  font-family: 'Lobster', cursive;
  text-align : center;
  color: #FFB74D;
}
</style>
